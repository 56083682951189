.first-page-wrapper {
    margin: auto;
    max-width: 870px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alert-wrapper {
    background: #000;
    color: #fff;
    padding: 10px 20px;
    white-space: nowrap;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.loading-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-wrapper > img {
    margin-right: 10px;
}

.flex-center {
    display: flex;
    align-items: center;
}

.first-page-content-top > span {
    font-size: 40px;
    font-weight: bold;
    color: #000;
    margin-left: 15px;
}

.first-page-content-center {
    margin: 100px 0 200px;
    justify-content: space-between;
    width: 100%;
}

.first-page-content-center > input {
    width: 650px;
    border: none;
    padding: 15px;
    border-bottom: 1px solid #000;
    outline: none;
}

.first-page-content-center > button {
    width: 150px;
    height: 45px;
    text-align: center;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    background: #fff;
    border: 1px solid #000;
    cursor: pointer;
    outline: none;
}

.first-page-content-center > button:hover {
    background: #000;
    color: #fff;
}

.first-page-content-bottom {
    color: #999;
    font-size: 14px;
    line-height: 20px;
}

.first-page-content-bottom > h4 {
    font-weight: normal;
    font-size: 20px;
    color: #000;
    text-align: center;
}

.first-page-content-bottom > p {
    margin-bottom: 20px;
}

.first-page-content-bottom > p > span {
    text-decoration-line: none;
    color: #0066ff;
    cursor: pointer;
}

.first-page-content-bottom > div > img {
    margin-right: 20px;
}
